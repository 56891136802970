



































































import { Vue, Component } from "vue-property-decorator";
import { productDetail } from "@/api/";
@Component
export default class product extends Vue {
    public tabIndex = 0;
    public info = null;
    public nextInfo = null;
    public labelList = [];

    public async mounted() {
        // let json = this.$route.query.json.toString() || ''
        // this.info = json && JSON.parse(json)
        // this.labelList = this.info && (this.info as any).label.split('、') || []
        // console.log(this.info)

        // let nextJson = this.$route.query.nextJson.toString() || ''
        // this.nextInfo = nextJson && JSON.parse(nextJson)
        // console.log(this.nextInfo)

        productDetail(this.$route.query.id).then((res) => {
            this.info = res;
            this.labelList = (this.info && (this.info as any).label.split("、")) || [];
        });
    }

    public handelClick() {}
}
